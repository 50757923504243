import Swiper, { Navigation } from 'swiper';
(function () {
  Swiper.use([Navigation]);
  new Swiper(".stepsSlider", {
    loop: true,
    effect: 'fade',
    navigation: {
      nextEl: ".swipeIconMineRight",
      prevEl: ".swipeIconMineLeft",
    },
  });
  new Swiper(".stepsSliderMobile", {
    loop: true,
    effect: 'coverflow',
    coverflowEffect: {
      rotate: 0,
      stretch: -60,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    },
    slidesPerView: "auto",
    centeredSlides: true,
  });
})()
